import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchPublicIP, generateHeader } from "../utils";

let publicIP = null;
fetchPublicIP().then((ip) => {
	publicIP = ip;
});

const customBaseQuery = async (args, api, extraOptions) => {
	const baseResult = await fetchBaseQuery({
		baseUrl: process.env.REACT_APP_DEV_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().UserDataReducer?.staffInfo?.accessToken;

			const apiHeaders = generateHeader();
			Object.entries(apiHeaders).forEach(([key, value]) => {
				headers.set(key, value);
			});

			headers.set("Content-Type", "application/json");
			headers.set("accept", "text/plain");
			headers.set("Access-Control-Expose-Headers", "access-token");
			headers.set("Access-Control-Allow-Origin", "*");
			headers.set("Access-Control-Allow-Methods", "*");
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			if (publicIP) {
				headers.set("remote-address", publicIP);
			}
			return headers;
		},
	})(args, api, extraOptions);

	const newResponse = {
		...baseResult,
	};
	const errorCode = newResponse?.data?.statusCode;
	if (errorCode === 401) {
		localStorage.clear();
		window.location.href = "/";
	}
	return baseResult;
};

export const userApi = createApi({
	reducerPath: "userApi",
	baseQuery: customBaseQuery,

	endpoints: (builder) => ({
		//activate user
		logout: builder.mutation({
			query: () => ({
				url: `/user/logout`,
				method: "POST",
			}),
		}),

		//activate user
		activateUser: builder.mutation({
			query: (body) => ({
				url: `/user/users/activate`,
				method: "POST",
				body,
			}),
		}),

		//deactivate user
		deactivateUser: builder.mutation({
			query: (body) => ({
				url: `/user/users/deactivate`,
				method: "POST",
				body,
			}),
		}),

		//get user
		getUser: builder.mutation({
			query: ({ username }) => ({
				url: `/user/users?username=${username}`,
				method: "GET",
			}),
		}),

		//get all user
		getAllUsers: builder.query({
			query: (token) => ({
				url: "user/users",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				method: "GET",
			}),
			// transformResponse: (response: { data}, meta, arg) =>
			//   response.data,
		}),

		//assign role
		addUser: builder.mutation({
			query: (body) => ({
				url: "/user/add-user",
				method: "POST",
				body,
			}),
		}),

		//get pending user
		pendingUserApproval: builder.mutation({
			query: (token) => ({
				url: "user/users-pending-approval",
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
		}),

		//approve user
		approveUser: builder.mutation({
			query: (body) => ({
				url: "user/approval-user",
				method: "POST",
				body,
			}),
		}),
	}),
});

export const {
	useActivateUserMutation,
	useDeactivateUserMutation,
	useGetAllUsersQuery,
	useGetUserMutation,
	useAddUserMutation,
	usePendingUserApprovalMutation,
	useApproveUserMutation,
	useLogoutMutation,
} = userApi;
