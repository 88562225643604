// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content {
  width: 500px;
  display: flex;
  flex-direction: column;
}
.btnHolder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;
}

.closeWrapper {
  cursor: pointer;
}

.top {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}

.modaltext {
  font-family: "Poppins", sans-serif;
  margin: 0px;
  padding: 0px;
  text-align: center;
  padding: 15px;
  color: #2d2235;
  font-size: 28px;
}

.bodyText {
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 15px;
  color: #2d2235;
}
`, "",{"version":3,"sources":["webpack://./src/components/InactiveContent/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,kCAAkC;EAClC,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,aAAa;EACb,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.content {\n  width: 500px;\n  display: flex;\n  flex-direction: column;\n}\n.btnHolder {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-top: 60px;\n}\n\n.closeWrapper {\n  cursor: pointer;\n}\n\n.top {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n  width: 100%;\n}\n\n.modaltext {\n  font-family: \"Poppins\", sans-serif;\n  margin: 0px;\n  padding: 0px;\n  text-align: center;\n  padding: 15px;\n  color: #2d2235;\n  font-size: 28px;\n}\n\n.bodyText {\n  font-family: \"Poppins\", sans-serif;\n  text-align: center;\n  padding: 15px;\n  color: #2d2235;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
