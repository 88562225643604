// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purple-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.purple-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #70279d;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s;
}

.purple-checkbox input:checked + .checkmark {
  background-color: #70279d;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.purple-checkbox input:checked + .checkmark:after {
  display: block;
}

.purple-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.label-text {
  margin-left: 8px;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/checkbox/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,SAAS;EACT,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,oDAAoD;AACtD;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,QAAQ;EACR,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".purple-checkbox {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  user-select: none;\n}\n\n.purple-checkbox input {\n  position: absolute;\n  opacity: 0;\n  cursor: pointer;\n  height: 0;\n  width: 0;\n}\n\n.checkmark {\n  position: relative;\n  height: 20px;\n  width: 20px;\n  background-color: #fff;\n  border: 2px solid #70279d;\n  border-radius: 4px;\n  transition: background-color 0.2s, border-color 0.2s;\n}\n\n.purple-checkbox input:checked + .checkmark {\n  background-color: #70279d;\n}\n\n.checkmark:after {\n  content: \"\";\n  position: absolute;\n  display: none;\n}\n\n.purple-checkbox input:checked + .checkmark:after {\n  display: block;\n}\n\n.purple-checkbox .checkmark:after {\n  left: 6px;\n  top: 2px;\n  width: 5px;\n  height: 10px;\n  border: solid white;\n  border-width: 0 3px 3px 0;\n  transform: rotate(45deg);\n}\n\n.label-text {\n  margin-left: 8px;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
