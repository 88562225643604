import React, { useState } from "react";
import PrimaryInput from "../PrimaryInput";
import { ReactComponent as CloseIcon } from "../../assets/imgs/close.svg";
import PrimaryButton from "../PrimaryButton";
import PrimaryModal from "../Modal";
import { useCreatePerfectionMutation } from "../../services/perfection";
import toast from "react-hot-toast";

export const perfectionPayloadData = {
  accountName: "",
  accountNumber: "",
  facilityAmount: "",
  region: "",
  branch: "",
  collateral: "",
  securityLocation: "",
  status: "",
};

const PerfectionCreator = ({ handleClose, refetch, setOpenCreateModal }) => {
  const [perfectionInfo, setPerfectionInfo] = useState(perfectionPayloadData);
  const [createPerfection, perfectionMutation] = useCreatePerfectionMutation();

  const onHandleChange = (e) => {
    setPerfectionInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCreatePerfection = async (type) => {
    console.log("formmm");

    const perfectionPayload = {
      accountName: perfectionInfo?.accountName,
      accountNumber: perfectionInfo?.accountNumber,
      facilityAmount: perfectionInfo?.facilityAmount,
      region: perfectionInfo?.region,
      branch: perfectionInfo?.branch,
    };

    try {
      console.log("gggg", perfectionPayload);
      const response = await createPerfection(perfectionPayload);
      console.log("response", response);
      if (response?.data?.statusCode === 200) {
        setOpenCreateModal(false);
        if (refetch) {
          refetch();
        }
        toast.success(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PrimaryModal general>
        <>
          <div className="modalTop">
            <p className="topTitle"> Create Perfection </p>
            <div className="iconWrapper">
              <CloseIcon onClick={handleClose} />
            </div>
          </div>

          <form action="" className="createForm">
            <PrimaryInput
              label={"Account Number"}
              placeholder={"Enter account number"}
              password={false}
              type={"text"}
              // register={register}
              name="accountNumber"
              onChange={onHandleChange}
              value={perfectionInfo?.accountNumber}
            />

            <PrimaryInput
              label={"Account Name"}
              placeholder={"Enter account name"}
              password={false}
              type={"text"}
              // register={register}
              name="accountName"
              onChange={onHandleChange}
              value={perfectionInfo?.accountName}
            />
            <PrimaryInput
              label={"Facility Amount"}
              placeholder={"Enter amount"}
              password={false}
              type={"text"}
              // register={register}
              name="facilityAmount"
              onChange={onHandleChange}
              value={perfectionInfo?.facilityAmount}
            />
            <PrimaryInput
              label={"Region"}
              placeholder={"Enter reguion"}
              password={false}
              type={"text"}
              // register={register}
              name="region"
              onChange={onHandleChange}
              value={perfectionInfo?.region}
            />
            <PrimaryInput
              label={"Branch"}
              placeholder={"Enter branch"}
              password={false}
              type={"text"}
              // register={register}
              name="branch"
              onChange={onHandleChange}
              value={perfectionInfo?.branch}
            />
          </form>
          <div className="modalBtnWrappper">
            <PrimaryButton
              title="Save"
              dark
              isLoading={perfectionMutation?.isLoading}
              onClick={handleCreatePerfection}
            />
          </div>
        </>
      </PrimaryModal>
    </>
  );
};

export default PerfectionCreator;
