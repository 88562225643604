// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  width: 100%;
  height: 56px;
  border-radius: 12px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  justify-content: center;
  border: none;
  padding-inline: 24px;
  transition: all 0.2s ease-in-out;
}
.btn {
  color: #5c2684;
  background-color: #ffffff;
  display: flex;
  gap: 5px;
  align-items: center;
}

.btn.dark {
  color: #ffffff;
  background-color: #5c2684;
}
.btn.gray {
  color: #5c2684;
  background-color: #ecebed;
  border: solid 1px #5c2684;
}
`, "",{"version":3,"sources":["webpack://./src/components/PrimaryButton/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kCAAkC;EAClC,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,YAAY;EACZ,oBAAoB;EACpB,gCAAgC;AAClC;AACA;EACE,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":[".btn {\n  width: 100%;\n  height: 56px;\n  border-radius: 12px;\n  font-family: \"Poppins\", sans-serif;\n  cursor: pointer;\n  font-size: 18px;\n  font-weight: 400;\n  text-align: center;\n  justify-content: center;\n  border: none;\n  padding-inline: 24px;\n  transition: all 0.2s ease-in-out;\n}\n.btn {\n  color: #5c2684;\n  background-color: #ffffff;\n  display: flex;\n  gap: 5px;\n  align-items: center;\n}\n\n.btn.dark {\n  color: #ffffff;\n  background-color: #5c2684;\n}\n.btn.gray {\n  color: #5c2684;\n  background-color: #ecebed;\n  border: solid 1px #5c2684;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
