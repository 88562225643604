import React, { useState } from "react";
import "./Perfection.css";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import SearchBar from "../../components/SearchBar/searchBar";
import Table from "../../components/Table/Table";
import PrimaryButton from "../../components/PrimaryButton";
import { HiDownload } from "react-icons/hi";
import { usePerfectionListQuery } from "../../services/perfection";
import { Oval } from "react-loading-icons";
import { useSelector } from "react-redux";
import PerfectionCreator from "../../components/perfectionCreator";
import { handleDownloadSheet } from "../../utils";
import Button from "../../components/Button/Button";

function Perfection() {
  const navigate = useNavigate();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const token = useSelector(
    (state) => state.UserDataReducer?.staffInfo.accessToken
  );
  const { data, refetch, isLoading } = usePerfectionListQuery(token);

  const columns = [
    {
      name: "S/N",
      selector: (row) => row.index,
      sortable: true,
    },
    {
      name: "Track ID",
      selector: (row) => row.trackId,
      sortable: true,
    },
    {
      name: "Account Number",
      selector: (row) => row.accountNumber,
      sortable: true,
    },
    {
      name: "Account Name",
      selector: (row) => row.accountName,
      sortable: true,
    },
    {
      name: "Facility Amount",
      selector: (row) => row.facilityAmount,
      sortable: true,
    },
    {
      name: "Region",
      selector: (row) => row.region,
      sortable: true,
    },
    {
      name: "Branch",
      selector: (row) => row.branch,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Button
          text={"View"}
          bodystyle={"tableButtonBody"}
          textstyle={"tableButtonText"}
          clickEvent={() => {
            navigate("/perfection-form", {
              state: { trackerId: row?.trackId },
            });
          }}
        />
      ),
    },
  ];

  const dataRow = data?.data?.map((data, index) => ({
    index: index + 1,
    trackId: data?.trackerId,
    accountNumber: data?.accountNumber,
    accountName: data?.accountName,
    facilityAmount: data?.facilityAmount,
    region: data?.region,
    // solicitorName: data?.solicitorName,
    branch: data?.branch,
  }));

  const header = [
    "S/N",
    "Tracker ID",
    "Account Number",
    "Account Name",
    "Facility Amount",
    "Region",
    "Branch",
    "Collateral",
    "Security Location",
    "Status",
    "Law Firm",
    "Commencement Date",
    "Created By",
    "Modified On",
  ];
  const dataBody = data?.data?.map((data, index) => [
    index + 1,
    data?.trackerId,
    data?.accountNumber,
    data?.accountName,
    new Intl.NumberFormat().format(data?.facilityAmount),
    data?.region,
    data?.branch,
    data?.collateral,
    data?.securityLocation,
    data?.status,
    data?.lawFirm,
    data?.commencementDate?.slice(0, 10),
    data?.createdBy,
    data?.dateModified?.slice(0, 10),
  ]);

  //close modal
  const handleClose = () => {
    setOpenCreateModal(false);
  };

  return (
    <div>
      <NavBar link3={"navlist-active"} />

      <div className="container">
        <div className="buttonContainer">
          <p className="title">Perfection Tracker</p>
          <PrimaryButton
            title={"Create Perfection"}
            light={false}
            onClick={() => setOpenCreateModal(true)}
          />
        </div>
        <div className="buttonContainer">
          <PrimaryButton
            title={"Export"}
            gray
            onClick={() =>
              handleDownloadSheet(header, dataBody, "Perfection_list")
            }
            leftIcon={<HiDownload />}
          />
          <div className="searchBarContainer">
            <SearchBar searchtext={"Search"} />
          </div>
        </div>

        <>
          {isLoading ? (
            <div className="tableLoader">
              <Oval stroke="#5c2684" fill="white" width={30} height={30} />
            </div>
          ) : (
            <Table columns={columns} rows={dataRow} />
          )}
        </>
      </div>

      {openCreateModal && (
        <PerfectionCreator
          handleClose={handleClose}
          refetch={refetch}
          setOpenCreateModal={setOpenCreateModal}
        />
      )}
    </div>
  );
}

export default Perfection;
