// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropContainer {
  border: solid red;
}

.invisibleBackDrop {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.dropDown {
  background: #fafafa;
  position: absolute;
  width: 150px;
  top: 25px;
  left: 100px;
  overflow-y: auto;
  padding: 0px !important;
  margin: 0px !important;
  z-index: 5;
}

.listItems {
  display: flex;
  height: 100%;
  margin: 0px !important;
  flex-direction: column;
  padding: 0px !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.listItem {
  display: flex;
  padding: 10px 24px;
  align-items: center;
  font-size: 12px;
  border: 1px solid #dfe2e6;
}

.listItem:hover {
  background-color: #5c2684;
  cursor: pointer;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/options/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,sBAAsB;EACtB,uBAAuB;EACvB,+CAA+C;AACjD;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,YAAY;AACd","sourcesContent":[".dropContainer {\n  border: solid red;\n}\n\n.invisibleBackDrop {\n  background-color: transparent;\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  z-index: 1;\n}\n\n.dropDown {\n  background: #fafafa;\n  position: absolute;\n  width: 150px;\n  top: 25px;\n  left: 100px;\n  overflow-y: auto;\n  padding: 0px !important;\n  margin: 0px !important;\n  z-index: 5;\n}\n\n.listItems {\n  display: flex;\n  height: 100%;\n  margin: 0px !important;\n  flex-direction: column;\n  padding: 0px !important;\n  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);\n}\n\n.listItem {\n  display: flex;\n  padding: 10px 24px;\n  align-items: center;\n  font-size: 12px;\n  border: 1px solid #dfe2e6;\n}\n\n.listItem:hover {\n  background-color: #5c2684;\n  cursor: pointer;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
