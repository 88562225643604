import React from "react";
import "./style.css";

const DropOptions = (openDrop, options, setOpenDrop) => {
  const handleOpenAction = (e) => {
    setOpenDrop(!openDrop);
  };

  return (
    <div className="dropContainer">
      <i
        className="fa-solid fa-ellipsis userActionIcon"
        onClick={handleOpenAction}
      ></i>
      {openDrop && (
        <>
          {/* {act && (
            <div
              onClick={() => setOpenDrop(false)}
              className="invisibleBackDrop"
            />
          )} */}
          <div className="dropDown">
            <ul className="listItems">
              {options?.map((option) => (
                <li
                  className="listItem"
                  key={option.id}
                  onClick={option.action}
                >
                  {option.title}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default DropOptions;
