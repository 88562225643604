// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dataTableContainer {
  width: 100%;
  border-radius: 10px;
}
#tableButtonBody {
  width: 138px;
  height: 48px;
  background-color: rgba(239, 233, 243, 1);
}
#tableButtonText {
  width: 39px;
  height: 19px;
  font-size: 19px;
  font-weight: 400;
  line-height: 22.8px;
  text-align: center;
  color: rgba(112, 39, 157, 1);
}
`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,wCAAwC;AAC1C;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,4BAA4B;AAC9B","sourcesContent":[".dataTableContainer {\n  width: 100%;\n  border-radius: 10px;\n}\n#tableButtonBody {\n  width: 138px;\n  height: 48px;\n  background-color: rgba(239, 233, 243, 1);\n}\n#tableButtonText {\n  width: 39px;\n  height: 19px;\n  font-size: 19px;\n  font-weight: 400;\n  line-height: 22.8px;\n  text-align: center;\n  color: rgba(112, 39, 157, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
