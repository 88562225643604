import React from "react";
import "./IntroHeader.css";
import { Container } from "react-bootstrap";

function IntroHeader({ header_text, para_text, IntroHeaderIdAttribute }) {
  return (
    <Container className="intro-contaner" id={IntroHeaderIdAttribute}>
      <p className="intro-header">{header_text}</p>
      <p className="intro-para">{para_text}</p>
    </Container>
  );
}

export default IntroHeader;
