import React from "react";
import "./Table.css";
import DataTable from "react-data-table-component";

function Table({ columns, rows, IdDataTableContainer }) {
  const customStyles = {
    head: {
      style: {
        borderTopLeftRadius: "20px",
        width: "auto",
      },
    },
    pagination: {
      style: {
        border: "none", // Remove default border
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: "rgba(3, 4, 5, 1)",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "19.2px",
        textAlign: "left",
      },
      pageButtonsStyle: {
        borderRadius: "8px", // Rounded buttons
        border: "0.5px solid rgba(112, 39, 157, 0.2)",
        height: "40px",
        width: "40px",
        padding: "8px",
        margin: "0 2px",
        cursor: "pointer",
        transition: "0.4s",
        color: "rgba(92, 38, 132, 1)",
        fill: "rgba(92, 38, 132, 1)",
        backgroundColor: "#rgba(239, 233, 243, 1)", // Primary color for page buttons

        "&:disabled": {
          cursor: "unset",
          color: "rgba(92, 38, 132, 1)",
          fill: "rgba(92, 38, 132, 1)",
          backgroundColor: "#rgba(239, 233, 243, 1)", // Disabled button color
        },
      },
      rowsPerPageText: {
        fontWeight: "400",
      },
    },
    headCells: {
      style: {
        backgroundColor: "rgba(239, 233, 243, 1)",
        color: "rgba(3, 4, 5, 1)",
        fontSize: "15.06px",
        fontWeight: "bolder",
        lineHeight: "18.07px",
        textAlign: "left",
        width: "auto",
        height: "69px",
      },
    },
    cells: {
      style: {
        color: "rgba(3, 4, 5, 1)",
        fontSize: "15.06px",
        fontWeight: "500",
        lineHeight: "18.07px",
        textAlign: "left",
        padding: "10px",
        margin: "1px",
      },
    },
    rows: {
      style: {
        minHeight: "72px",
      },
    },
  };
  const conditionalRowStyle = [
    {
      when: (row) => true,
      style: {
        "&:hover": {
          backgroundColor: "rgba(239, 233, 243, 0.5)",
        },
      },
    },
  ];
  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  return (
    <div className="dataTableContainer" id={IdDataTableContainer}>
      <DataTable
        columns={columns}
        data={rows}
        customStyles={customStyles}
        pagination
        paginationPerPage={6}
        paginationRowsPerPageOptions={[10, 20, 30]}
        paginationComponentOptions={paginationComponentOptions}
        conditionalRowStyles={conditionalRowStyle}
      />
    </div>
  );
}

export default Table;
