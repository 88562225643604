import { configureStore } from "@reduxjs/toolkit";

import { authApi } from "../services/auth";
import { UserDataReducer } from "./slice";
import { userApi } from "../services/user";
import { perfectionApi } from "../services/perfection";
import { litigationApi } from "../services/litigation";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [perfectionApi.reducerPath]: perfectionApi.reducer,
    [litigationApi.reducerPath]: litigationApi.reducer,
    UserDataReducer: UserDataReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      userApi.middleware,
      perfectionApi.middleware,
      litigationApi.middleware,
    ]),
});
