import React, { useState } from "react";
import "./style.css";
import { ReactComponent as EyeIcon } from "../../assets/imgs/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../../assets/imgs/eye-slash.svg";

function PrimaryInput({
  label,
  name,
  placeholder,
  type,
  value,
  password,
  leftIcon,
  onChange,
  register,
  error,
}) {
  const [show, setShow] = useState(false);

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <div className="inputWrapper">
        {leftIcon && <>{leftIcon}</>}
        {register ? (
          <input
            type={!show ? type || "password" : "text"}
            id={name}
            name={name}
            value={value}
            placeholder={placeholder}
            className="custom-input"
            {...register(name)}
          />
        ) : (
          <input
            type={!show ? type || "password" : "text"}
            id={name}
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            className="custom-input"
          />
        )}

        {password && (
          <div className="eyeIcon" onClick={() => setShow(!show)}>
            {!show ? <EyeIcon /> : <EyeSlashIcon />}
          </div>
        )}
      </div>
      {error && <p className="errorMessage">{error}</p>}
    </div>
  );
}

export default PrimaryInput;
