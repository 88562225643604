// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  width: 100%;
  display: flex;
  background: #fafafa;
  align-items: center;
  border: 1px solid #e1e1de;
  border-radius: 4px;
}

.check-wrap {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-flow: column;
}

.input {
  border-radius: 12px;
  width: 100%;
  min-height: 100px;
  border: none;
  padding: 16px 24px;
  background: none;
  color: black;
  outline: none;
  box-sizing: border-box;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input::placeholder {
  font-size: 12px;
}

label {
  font-weight: 400;
  font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/components/TextArea/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;;EAEE,wBAAwB;AAC1B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".input-container {\n  width: 100%;\n  display: flex;\n  background: #fafafa;\n  align-items: center;\n  border: 1px solid #e1e1de;\n  border-radius: 4px;\n}\n\n.check-wrap {\n  width: 100%;\n  display: flex;\n  gap: 10px;\n  flex-flow: column;\n}\n\n.input {\n  border-radius: 12px;\n  width: 100%;\n  min-height: 100px;\n  border: none;\n  padding: 16px 24px;\n  background: none;\n  color: black;\n  outline: none;\n  box-sizing: border-box;\n}\n\n.input::-webkit-outer-spin-button,\n.input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n}\n\n.input::placeholder {\n  font-size: 12px;\n}\n\nlabel {\n  font-weight: 400;\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
