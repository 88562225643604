import React from "react";
import "./Dashboard.css";
import NavBar from "../../components/NavBar/NavBar";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PrimaryButton from "../../components/PrimaryButton";
import { HiLogin } from "react-icons/hi";
import { useSelector } from "react-redux";
import { handleLogout } from "../../utils";
import { useLogoutMutation } from "../../services/user";
import PerfectionCreator from "../../components/perfectionCreator";

function Dashboard() {
  const staffInfo = useSelector((state) => state.UserDataReducer?.staffInfo);

  let carouselHeaderText;
  let carouselParaText;
  let carouselButtonPath;

  const [carouselEffect, setCarouselEffect] = useState(true);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();

  const changingEffect = () => {
    const perfectionIndecator = document.getElementById("PerfectionIndecator");
    const litigationIndecator = document.getElementById("LitigationIndecator");

    if (carouselEffect) {
      carouselHeaderText = "Perfection Tracker";
      carouselParaText =
        "Do more today!, Start by checking the perfection list tracker ";
      carouselButtonPath = "/perfection";
      if (perfectionIndecator) {
        perfectionIndecator.classList.add("active");
        litigationIndecator.classList.remove("active");
      }
    } else {
      carouselHeaderText = "Litigation Tracker";
      carouselParaText =
        "Do more today!, Start by checking the litigation list tracker ";
      carouselButtonPath = "/litigation";
      litigationIndecator.classList.add("active");
      perfectionIndecator.classList.remove("active");
    }
  };

  const controlChangingEffect = () => {
    setTimeout(() => {
      carouselEffect ? setCarouselEffect(false) : setCarouselEffect(true);
    }, 5000);
    changingEffect();
  };

  controlChangingEffect();

  const handleClose = () => {
    setOpenCreateModal(false);
  };

  return (
    <div className="bodyContainter">
      <NavBar link1={"navlist-active"} />
      <div className="container">
        <div className="profile">
          <p className="title">Welcome {staffInfo?.user?.displayName}</p>
          <p className="subTitle">Get started for the day.</p>
        </div>

        <div className="contentContainer">
          <div className="leftContainer">
            <div className="userInfosection">
              <div className="userInfoIndicator">
                <p className="userInfoIndicatorText">
                  {staffInfo?.user?.displayName?.slice(0, 1)}
                </p>
              </div>
              <div className="userInfoDisplay">
                <h2 className="userInfoName">
                  {staffInfo?.user?.displayName}{" "}
                </h2>
                <p className="userInfoEmail">{staffInfo?.user?.email}</p>
              </div>
              <PrimaryButton
                title={"Log out"}
                gray
                onClick={() => handleLogout(logout, navigate)}
                leftIcon={<HiLogin />}
              />
            </div>
          </div>
          <div className="rightContainer">
            <div className="perfectionSection">
              <div className="perfectionTextBody">
                <h1 className="perfectionHeader">{carouselHeaderText}</h1>
                <p className="perfectionText">{carouselParaText}</p>
              </div>
              <div className="buttonHolder">
                <PrimaryButton
                  title={"View Tracker List"}
                  light={true}
                  onClick={() => navigate(`${carouselButtonPath}`)}
                />
                <div className="carouselEffectIndecatorWarpper">
                  <span
                    className="carouselEffectIndecator active"
                    id="PerfectionIndecator"
                  ></span>
                  <span
                    className="carouselEffectIndecator"
                    id="LitigationIndecator"
                  ></span>
                </div>
              </div>
            </div>

            <h1 className="quickActionHeader">Quick Actions</h1>
            <div className="quickActonSection">
              <div
                className="quickActonContent"
                onClick={() => {
                  setOpenCreateModal(true);
                }}
              >
                <i className="fa-solid fa-cube actionIcon"></i>
                <p className="actionText1">Create a perfect tracker </p>
              </div>
              <div
                className="quickActonContent"
                onClick={() => {
                  navigate("/litigation-form");
                }}
              >
                <i className="fa-brands fa-viacoin actionIcon"></i>
                <p className="actionText2">Create a ligitation </p>
              </div>
              <div
                className="quickActonContent"
                onClick={() => {
                  navigate("/userAccount");
                }}
              >
                <i className="fa-solid fa-user actionIcon"></i>
                <p className="actionText3">Add a new user </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openCreateModal && (
        <PerfectionCreator
          handleClose={handleClose}
          setOpenCreateModal={setOpenCreateModal}
        />
      )}
    </div>
  );
}

export default Dashboard;
