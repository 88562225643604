import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchPublicIP, generateHeader } from "../utils";

let publicIP = null;
fetchPublicIP().then((ip) => {
	publicIP = ip;
});

const customBaseQuery = async (args, api, extraOptions) => {
	const baseResult = await fetchBaseQuery({
		baseUrl: process.env.REACT_APP_DEV_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().UserDataReducer?.staffInfo?.accessToken;

			const apiHeaders = generateHeader();
			Object.entries(apiHeaders).forEach(([key, value]) => {
				headers.set(key, value);
			});

			headers.set("Content-Type", "application/json");

			headers.set("accept", "text/plain");
			headers.set("Access-Control-Expose-Headers", "access-token");
			headers.set("Access-Control-Allow-Origin", "*");
			headers.set("Access-Control-Allow-Methods", "*");
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}

			if (publicIP) {
				headers.set("remote-address", publicIP);
			}

			return headers;
		},
	})(args, api, extraOptions);

	const newResponse = {
		...baseResult,
	};
	const errorCode = newResponse?.data?.statusCode;
	if (errorCode === 401) {
		localStorage.clear();
		window.location.href = "/";
	}
	return baseResult;
};

export const perfectionApi = createApi({
	reducerPath: "perfectionApi",
	baseQuery: customBaseQuery,

	endpoints: (builder) => ({
		//get all solicitor
		getSolicitor: builder.query({
			query: (token) => ({
				url: "/solicitor",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				method: "GET",
			}),
		}),

		//assign role
		perfectionList: builder.query({
			query: (token) => ({
				url: "/perfection",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				method: "GET",
			}),
		}),

		createPerfection: builder.mutation({
			query: (body) => ({
				url: "/perfection",
				method: "POST",
				body,
			}),
		}),

		//delete perfection
		deletePerfection: builder.mutation({
			query: (trackerId) => ({
				url: `/perfection/${trackerId}`,
				method: "DELETE",
			}),
		}),
		//put perfection
		updatePerfection: builder.mutation({
			query: ({ body, trackerId }) => ({
				url: `/perfection/${trackerId}`,
				method: "PUT",
				body,
			}),
		}),
		//delete perfection
		deletedPerfection: builder.query({
			query: (token) => ({
				url: "/perfection/pending-delete-approval",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				method: "GET",
			}),
		}),

		//update delete perfection
		updateDeletedPerfection: builder.mutation({
			query: (body) => ({
				url: "/perfection/approve-decline-delete",
				method: "PUT",
				body,
			}),
		}),
	}),
});

export const {
	useGetSolicitorQuery,
	usePerfectionListQuery,
	useCreatePerfectionMutation,
	useUpdatePerfectionMutation,
	useDeletePerfectionMutation,
	useDeletedPerfectionQuery,
	useUpdateDeletedPerfectionMutation,
} = perfectionApi;
