// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchContainer {
  display: flex;
  align-items: center;
  height: 56px;
  padding-inline: 16px;
  gap: 5px;
  border-radius: 12px;
  border: 1px solid rgba(223, 226, 230, 1);
  background-color: rgba(250, 251, 251, 1);
}
.searchIcon {
  width: 24px;
  height: 24px;
  gap: 0px;
  opacity: 0px;
  color: rgba(137, 147, 164, 1);
  cursor: pointer;
}
.searchInput {
  height: 29px;
  border: none;
  background: none;
}
input.searchInput:focus {
  outline: none;
}
.searchInput[type="text"] {
  font-size: 16px;
  text-align: left;
}
.searchInput::placeholder {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/SearchBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,oBAAoB;EACpB,QAAQ;EACR,mBAAmB;EACnB,wCAAwC;EACxC,wCAAwC;AAC1C;AACA;EACE,WAAW;EACX,YAAY;EACZ,QAAQ;EACR,YAAY;EACZ,6BAA6B;EAC7B,eAAe;AACjB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".searchContainer {\n  display: flex;\n  align-items: center;\n  height: 56px;\n  padding-inline: 16px;\n  gap: 5px;\n  border-radius: 12px;\n  border: 1px solid rgba(223, 226, 230, 1);\n  background-color: rgba(250, 251, 251, 1);\n}\n.searchIcon {\n  width: 24px;\n  height: 24px;\n  gap: 0px;\n  opacity: 0px;\n  color: rgba(137, 147, 164, 1);\n  cursor: pointer;\n}\n.searchInput {\n  height: 29px;\n  border: none;\n  background: none;\n}\ninput.searchInput:focus {\n  outline: none;\n}\n.searchInput[type=\"text\"] {\n  font-size: 16px;\n  text-align: left;\n}\n.searchInput::placeholder {\n  font-size: 16px;\n  font-weight: 400;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
