// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.modalWrapper {
  background: #fff;
  border-radius: 24px;
}
.modalContent {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.modalTexts {
  font-family: "Poppins", sans-serif;
  width: 300px;
  margin-top: 10px;
  text-align: center;
  padding: 15px;
  color: #2d2235;
  margin-top: 30px;
}
.closeWrapper {
  cursor: pointer;
}
.top {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kCAAkC;EAClC,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,qBAAqB;EACrB,WAAW;AACb","sourcesContent":[".background {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 99;\n}\n.modalWrapper {\n  background: #fff;\n  border-radius: 24px;\n}\n.modalContent {\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  align-items: center;\n  padding: 50px;\n}\n\n.modalTexts {\n  font-family: \"Poppins\", sans-serif;\n  width: 300px;\n  margin-top: 10px;\n  text-align: center;\n  padding: 15px;\n  color: #2d2235;\n  margin-top: 30px;\n}\n.closeWrapper {\n  cursor: pointer;\n}\n.top {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
