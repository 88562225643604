import React, { useEffect, useState } from "react";
import "./Perfection.css";
import NavBar from "../../components/NavBar/NavBar";

import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";
import PrimaryInput from "../../components/PrimaryInput";
import { ReactComponent as CloseIcon } from "../../assets/imgs/close.svg";
import TextArea from "../../components/TextArea";
import DateInput from "../../components/DatePicker/Date";
import { HiChevronLeft } from "react-icons/hi";
import { litigationSchema } from "../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  useDeletePerfectionMutation,
  usePerfectionListQuery,
  useUpdatePerfectionMutation,
  useUpdateDeletedPerfectionMutation,
} from "../../services/perfection";
import { perfectionPayloadData } from "../../components/perfectionCreator";
import PrimaryModal from "../../components/Modal";

function PerfectionDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(litigationSchema),
  });
  const token = useSelector(
    (state) => state.UserDataReducer?.staffInfo.accessToken
  );
  const [prefilledData, setPrefilledData] = useState({});
  const [selectedProcessDate, setSelectedProcessDate] = useState("");
  const [perfectionInfo, setPerfectionInfo] = useState(perfectionPayloadData);
  const [openDeteleConfirmation, setOpenDeleteConfirmation] = useState(false);

  //endpoints
  const { data, refetch } = usePerfectionListQuery(token);
  const [updatePerfection, updateMutation] = useUpdatePerfectionMutation();
  const [deletePerfection, deleteMutation] = useDeletePerfectionMutation();
  const [updateDeletedPerfection] = useUpdateDeletedPerfectionMutation();

  const onHandleChange = (e) => {
    setPerfectionInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if ((state?.trackerId || state?.pendingId) && data?.data) {
      const prefilled = data?.data?.find(
        (item) =>
          item?.trackerId === state?.trackerId ||
          item?.trackerId === state?.pendingId
      );

      setPrefilledData(prefilled);

      setPerfectionInfo((prev) => ({
        ...prev,
        accountName: prefilled?.accountName,
        accountNumber: prefilled?.accountNumber,
        facilityAmount: new Intl.NumberFormat().format(
          prefilled?.facilityAmount
        ),
        region: prefilled?.region,
        branch: prefilled?.branch,
        collateral: prefilled?.collateral,
        securityLocation: prefilled?.securityLocation,
        status: prefilled?.status,
      }));
      setSelectedProcessDate(prefilled?.commencementDate?.slice(0, 10));
    }
  }, [state?.trackerId, state?.pendingId, data?.data]);

  const onSubmit = async () => {
    const payload = {
      accountName: perfectionInfo?.accountName,
      accountNumber: perfectionInfo?.accountNumber,
      facilityAmount: perfectionInfo?.facilityAmount,
      region: perfectionInfo?.region,
      branch: perfectionInfo?.branch,
      collateral: perfectionInfo?.collateral,
      securityLocation: perfectionInfo?.securityLocation,
      commencementDate: selectedProcessDate,
      status: perfectionInfo?.status,
      comment: "string",
      solicitorId: 4,
      modificationComment: "string",
      dateModified: "2024-07-01",
      briefDate: "2024-07-01",
      conclusion: "string",
      guarantorAddress: "string",
      omv: "string",
      fsv: "string",
      valuation: "string",
    };

    try {
      const response = await updatePerfection({
        body: payload,
        trackerId: state?.trackerId,
      });
      console.log("response edit", response);
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);
        navigate("/perfection");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteLitigation = async () => {
    try {
      const response = await deletePerfection(state?.trackerId);

      console.log("response edit", response);
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);
        navigate("/perfection");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateDeleteLitigation = async (type) => {
    try {
      const payload = {
        trackerId: state?.pendingId,
        isApproved: type === "APPROVE" ? true : false,
      };
      const response = await updateDeletedPerfection(payload);

      console.log("response edit", response);
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);
        navigate("/litigation");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpenDeleteConfirmation(false);
  };

  return (
    <div>
      <NavBar link4={"navlist-active"} />
      <div className="container">
        <p className="title">Perfection Details</p>
        <div className="buttonContainer">
          <PrimaryButton
            title={"Back"}
            gray
            onClick={() => navigate(-1)}
            leftIcon={<HiChevronLeft />}
          />

          {state?.trackerId && (
            <div className="btnHold">
              <PrimaryButton
                title={"Edit"}
                light={false}
                onClick={onSubmit}
                isLoading={updateMutation?.isLoading}
              />
              <PrimaryButton
                title={"Delete"}
                gray
                onClick={() => setOpenDeleteConfirmation(true)}
              />
            </div>
          )}
          {state?.pendingId && (
            <div className="pendingBtnHold">
              <PrimaryButton
                title={"Approve"}
                light={false}
                onClick={() => handleUpdateDeleteLitigation("APPROVE")}
                isLoading={updateMutation?.isLoading}
              />
              <PrimaryButton
                title={"Decline"}
                gray
                onClick={() => handleUpdateDeleteLitigation("DECLINE")}
                isLoading={deleteMutation?.isLoading}
              />
            </div>
          )}
        </div>
        <div className="formContainer">
          <p className="title">Perfection Information</p>
          <div className="form">
            <div className="gridWrapper">
              <PrimaryInput
                label={"Account Name"}
                placeholder={"Account Name"}
                password={false}
                type={"text"}
                // register={register}
                name="accountNumber"
                onChange={onHandleChange}
                value={perfectionInfo?.accountNumber}
              />

              <PrimaryInput
                label={"Account Name"}
                placeholder={"Enter account name"}
                password={false}
                type={"text"}
                // register={register}
                name="accountName"
                onChange={onHandleChange}
                value={perfectionInfo?.accountName}
              />
            </div>

            <div className="gridWrapper">
              <PrimaryInput
                label={"Facility Amount"}
                placeholder={"Enter amount"}
                password={false}
                type={"text"}
                // register={register}
                name="facilityAmount"
                onChange={onHandleChange}
                value={perfectionInfo?.facilityAmount}
              />
              <PrimaryInput
                label={"Region"}
                password={false}
                type={"text"}
                placeholder={"Region"}
                // register={register}
                name="region"
                onChange={onHandleChange}
                value={perfectionInfo?.region}
              />
            </div>
            <PrimaryInput
              label={"Branch"}
              placeholder={"John Doe"}
              type={"text"}
              // register={register}
              name="branch"
              onChange={onHandleChange}
              value={perfectionInfo?.branch}
            />
            <div className="gridWrapper">
              <TextArea
                label={"Collateral"}
                // register={register}
                type="text"
                name="collateral"
                onChange={onHandleChange}
                value={perfectionInfo?.collateral}
              />
              <TextArea
                label={"Security Location"}
                // register={register}
                type="text"
                name="securityLocation"
                onChange={onHandleChange}
                value={perfectionInfo?.securityLocation}
              />
            </div>
            <div className="gridWrapper">
              <DateInput
                label={"Perfection Process Date"}
                selectDate={setSelectedProcessDate}
                value={selectedProcessDate}
              />
              <PrimaryInput
                label={"Perfection Status"}
                password={false}
                type={"text"}
                placeholder={"status"}
                // register={register}
                name="status"
                value={perfectionInfo?.status}
              />
            </div>
          </div>
        </div>
      </div>

      {openDeteleConfirmation && (
        <PrimaryModal general>
          <div className="modalTop">
            <p className="topTitle"> Delete Perfection </p>
            <div className="iconWrapper">
              <CloseIcon onClick={handleClose} />
            </div>
          </div>
          <p className="modalParagraph">
            This can’t be reversed, all data will be deleted permanently{" "}
          </p>
          <div className="modalBtnWrappper">
            <PrimaryButton
              title={"Cancel"}
              gray
              onClick={() => setOpenDeleteConfirmation(false)}
            />
            <PrimaryButton
              title={"Delete"}
              light={false}
              onClick={handleDeleteLitigation}
              isLoading={deleteMutation?.isLoading}
            />
          </div>
        </PrimaryModal>
      )}
    </div>
  );
}

export default PerfectionDetails;
