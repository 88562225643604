import React, { useState, useRef } from "react";
import { ReactComponent as CalendarIcon } from "../../assets/imgs/calendarblack.svg";
import "react-calendar/dist/Calendar.css";
import { Calendar } from "react-calendar";
import format from "date-fns/format";
import "./style.css";
import "./calender.css";

const DateInput = ({
  showModal,
  selectDate,
  label,
  handleDateChange,
  disablePast,
  value,
}) => {
  const [date, setDate] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [active, setActive] = useState(false);
  const inputRef = useRef(null);

  const hideCalendar = () => {
    setShowCalendar(false);
  };

  const pickDay = (day) => {
    const selectedDate = format(day, "yyyy-MM-dd");
    setDate(selectedDate);
    selectDate(selectedDate);

    hideCalendar();
  };

  const handleBorder = () => {
    setActive(!active);
  };

  return (
    <div className="wrapper" key="DateInput">
      <div className="top">
        <label className="label">{label}</label>
      </div>
      <div
        className="input-wrapper"
        style={{ border: active ? "1px solid ##C2684" : "1px solid #ececec" }}
        ref={inputRef}
        onFocus={handleBorder}
        onClick={() => setShowCalendar((prev) => !prev)}
        onBlur={() => setActive(false)}
      >
        <input
          className="input"
          type="text"
          placeholder="DD/MM/YY"
          readOnly
          value={value === "" ? "" : value}
        />
        <div className="icon-wrapper">
          <label htmlFor="date">
            <CalendarIcon />
          </label>
        </div>
      </div>
      {showCalendar && (
        <>
          <div className="calendar-wrapper">
            {disablePast ? (
              <Calendar
                onClickDay={pickDay}
                className={"calendar"}
                minDate={new Date()}
              />
            ) : (
              <Calendar onClickDay={pickDay} className={"calendar"} />
            )}
          </div>
          <div className="transparent-backdrop" onClick={hideCalendar} />
        </>
      )}
    </div>
  );
};

export default DateInput;
