import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchPublicIP, generateHeader } from "../utils";

let publicIP = null;
fetchPublicIP().then((ip) => {
	publicIP = ip;
});
const customBaseQuery = async (args, api, extraOptions) => {
	const baseResult = await fetchBaseQuery({
		baseUrl: process.env.REACT_APP_DEV_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().UserDataReducer?.staffInfo?.accessToken;

			const apiHeaders = generateHeader();
			Object.entries(apiHeaders).forEach(([key, value]) => {
				headers.set(key, value);
			});

			headers.set("Content-Type", "application/json");

			headers.set("accept", "text/plain");
			headers.set("Access-Control-Expose-Headers", "access-token");
			headers.set("Access-Control-Allow-Origin", "*");
			headers.set("Access-Control-Allow-Methods", "*");
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			if (publicIP) {
				headers.set("remote-address", publicIP);
			}
			return headers;
		},
	})(args, api, extraOptions);

	const newResponse = {
		...baseResult,
	};
	const errorCode = newResponse?.data?.statusCode;
	if (errorCode === 401) {
		localStorage.clear();
		window.location.href = "/";
	}
	return baseResult;
};

export const litigationApi = createApi({
	reducerPath: "litigationApi",
	baseQuery: customBaseQuery,

	endpoints: (builder) => ({
		//get all solicitor
		getSolicitor: builder.mutation({
			query: () => ({
				url: "/solicitor",

				method: "GET",
			}),
		}),

		//get litigation
		getLitigation: builder.query({
			query: (token) => ({
				url: "/litigation",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				method: "GET",
			}),
		}),

		//post litigation
		postLitigation: builder.mutation({
			query: (body) => ({
				url: "/litigation",
				method: "POST",
				body,
			}),
		}),

		//put litigation
		updateLitigation: builder.mutation({
			query: ({ body, trackerId }) => ({
				url: `/litigation/${trackerId}`,
				method: "PUT",
				body,
			}),
		}),

		//delete litigation
		deleteLitigation: builder.mutation({
			query: (trackerId) => ({
				url: `/litigation/${trackerId}`,
				method: "DELETE",
			}),
		}),

		//delete litigation
		deletedLitigation: builder.query({
			query: (token) => ({
				url: "/litigation/pending-delete-approval",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				method: "GET",
			}),
		}),

		//update deleted litigation
		updateDeletedLitigation: builder.mutation({
			query: (body) => ({
				url: "/litigation/approve-decline-delete",
				method: "PUT",
				body,
			}),
		}),
	}),
});

export const {
	useGetSolicitorMutation,
	useGetLitigationQuery,
	usePostLitigationMutation,
	useUpdateLitigationMutation,
	useDeleteLitigationMutation,
	useDeletedLitigationQuery,
	useUpdateDeletedLitigationMutation,
} = litigationApi;
