import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import PrimaryButton from "../PrimaryButton";
import { handleLogout } from "../../utils";
import { useLogoutMutation } from "../../services/user";

const InactiveContent = ({ handleCancel, counter, sessionTime }) => {
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="content">
        <h4 className="modaltext">You have been idle for {sessionTime}mins!</h4>
        <p className="bodyText">
          You are about to force log off your session in {counter} min. Press
          cancel to stay ?
        </p>
      </div>
      <div className="btnHolder">
        <PrimaryButton title="Cancel" gray onClick={handleCancel} />
        <PrimaryButton
          title="Logout"
          dark
          onClick={() => handleLogout(logout, navigate)}
        />
      </div>
    </div>
  );
};

export default InactiveContent;
