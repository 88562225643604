import { createSlice } from "@reduxjs/toolkit";

// This slice will hold user needed information
const UserData = createSlice({
  name: "User data",
  initialState: {
    password: "",
    staffInfo: {},
  },
  reducers: {
    savePassword: (state, action) => {
      state.password = action.payload;
    },
    saveStaffInfo: (state, action) => {
      state.staffInfo = action.payload;
    },
  },
});
export const UserDataReducer = UserData.reducer;

export const { savePassword, saveStaffInfo } = UserData.actions;
