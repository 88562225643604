// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 50px 60px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.title {
  font-size: 24px;
}
.gridWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.searchBarContainer {
  width: 300px;
}
.modalBtnWrappper {
  display: flex;
  margin-top: 24px;
  width: 100%;
  justify-content: space-between;
}

.modalTop {
  width: 500px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.topTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #2d2235;
}

.modalParagraph {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #2d2235;
}

.iconWrapper {
  cursor: pointer;
  display: flex;
  gap: 10px;
  flex-direction: row;
}
.buttonContainer {
  justify-content: space-between;
  display: flex;
  margin-bottom: 12px;
}
.formContainer {
  background-color: white;
  padding: 30px;
  margin-top: 24px;
}
.form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.createForm {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Perfection/Perfection.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;AACA;EACE,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,uBAAuB;EACvB,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;AACb","sourcesContent":[".container {\n  padding: 50px 60px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n.title {\n  font-size: 24px;\n}\n.gridWrapper {\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 20px;\n}\n.searchBarContainer {\n  width: 300px;\n}\n.modalBtnWrappper {\n  display: flex;\n  margin-top: 24px;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.modalTop {\n  width: 500px;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 16px;\n}\n.topTitle {\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 120%;\n  color: #2d2235;\n}\n\n.modalParagraph {\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 120%;\n  color: #2d2235;\n}\n\n.iconWrapper {\n  cursor: pointer;\n  display: flex;\n  gap: 10px;\n  flex-direction: row;\n}\n.buttonContainer {\n  justify-content: space-between;\n  display: flex;\n  margin-bottom: 12px;\n}\n.formContainer {\n  background-color: white;\n  padding: 30px;\n  margin-top: 24px;\n}\n.form {\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.createForm {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
