import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchPublicIP, generateHeader } from "../utils";

let publicIP = null;
fetchPublicIP().then((ip) => {
	publicIP = ip;
});

export const authApi = createApi({
	reducerPath: "authsApi", // a unique key that defines where the redux store will store the cache.
	baseQuery: fetchBaseQuery({
		// the base query used by each endpoint to request data.
		baseUrl: process.env.REACT_APP_DEV_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			const password = getState()?.UserDataReducer?.password;
			const encryptedPassword =
				getState()?.UserDataReducer?.encryptedPassword;
			const isLive = process.env.REACT_APP_LIVE;
			const pass = isLive === "true" ? encryptedPassword : password;

			const apiHeaders = generateHeader();
			Object.entries(apiHeaders).forEach(([key, value]) => {
				headers.set(key, value);
			});

			headers.set("Content-Type", "application/json");
			headers.set("Access-Control-Allow-Origin", "*");
			headers.set("Access-Control-Allow-Headers", "Content-Type");
			headers.set(
				"Access-Control-Allow-Methods",
				"OPTIONS,POST,GET,PATCH"
			);
			headers.set("password", pass);

			if (publicIP) {
				headers.set("Remote-Address", publicIP);
			}
		},
	}),
	tagTypes: ["User"],
	endpoints: (builder) => ({
		// actions we want to perform.
		//POST requests
		//user registration

		//user login
		loginUser: builder.mutation({
			query: (values) => ({
				url: "/user/login",
				method: "POST",
				body: {
					username: values.username,
					token: values.token,
				},
				headers: {
					"Content-type": "application/json; charset=UTF-8",
				},
			}),
			invalidatesTags: ["User"],
		}),

		//logout all
		logoutAll: builder.mutation({
			query: ({ username }) => ({
				url: `/user/logout-all?username=${username}`,
				method: "POST",
			}),
		}),

		// get reset token
		getToken: builder.mutation({
			query: ({ username }) => ({
				url: `/user/generate-reset-token?username=${username}`,
				method: "GET",
			}),
		}),

		//login
		resetUser: builder.mutation({
			query: (body) => ({
				url: "/user/reset-user",
				method: "POST",
				body,
			}),
		}),
	}),
});

//custom hooks
export const {
	useLoginUserMutation,
	useLogoutAllMutation,
	useGetTokenMutation,
	useResetUserMutation,
} = authApi;
