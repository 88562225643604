import React from "react";
import { useState } from "react";
import "./UserAccount.css";
import NavBar from "../../components/NavBar/NavBar";
import Button from "../../components/Button/Button";
import SearchBar from "../../components/SearchBar/searchBar";
import Table from "../../components/Table/Table";
import { HiPlus } from "react-icons/hi";
import PrimaryButton from "../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useAddUserMutation, useGetAllUsersQuery } from "../../services/user";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../assets/imgs/close.svg";
import PrimaryModal from "../../components/Modal";
import PrimaryInput from "../../components/PrimaryInput";
import PurpleCheckbox from "../../components/checkbox";
import { Oval } from "react-loading-icons";
import toast from "react-hot-toast";
import DropOptions from "../../components/options";

function UserAccount() {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [isAdminChecked, setIsAdminChecked] = useState(false);
  const [isReadOnlyChecked, setIsReadOnlyChecked] = useState(false);
  const [isLitigationChecked, setIsLitigationChecked] = useState(false);
  const [isReadWriteChecked, setIsReadWriteChecked] = useState(false);
  const [username, setUsername] = useState("");
  const [optionAtion, setOptionAtion] = useState(false);

  const token = useSelector(
    (state) => state.UserDataReducer?.staffInfo.accessToken
  );
  const { data, refetch, isLoading } = useGetAllUsersQuery(token);
  const [addUser, addMutation] = useAddUserMutation();
  const handleCheckboxChange = (type) => {
    switch (type) {
      case "admin":
        setIsAdminChecked((prev) => !prev);
        setIsReadOnlyChecked(false);
        setIsLitigationChecked(false);
        setIsReadWriteChecked(false);
        break;
      case "only":
        setIsReadOnlyChecked((prev) => !prev);
        setIsAdminChecked(false);
        setIsLitigationChecked(false);
        setIsReadWriteChecked(false);
        break;
      case "litigation":
        setIsLitigationChecked((prev) => !prev);
        setIsReadOnlyChecked(false);
        setIsAdminChecked(false);
        setIsReadWriteChecked(false);
        break;
      case "write":
        setIsReadWriteChecked((prev) => !prev);
        setIsReadOnlyChecked((prev) => !prev);
        setIsAdminChecked(false);
        setIsLitigationChecked(false);
        break;
      default:
        break;
    }
  };
  const handleOpenAction = (id) => {
    console.log("test", id);
    if (data?.data?.find((item) => item.id === id)) {
      setOptionAtion((prev) => !prev);
    }
  };

  const handleSubmit = async () => {
    try {
      const data = {
        username: username,
        administrator: isAdminChecked,
        perfectionReadOnly: isReadOnlyChecked,
        perfectionReadAndWrite: isReadWriteChecked,
        litigation: isLitigationChecked,
      };
      const response = await addUser(data);
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);
        refetch();
        setOpenModal(false);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      name: "S/N",
      selector: (row) => row.index,
      sortable: true,
    },
    {
      name: "Userame",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Role",
      cell: (row) => (
        <Button
          text={"Change Role"}
          bodystyle={"tableButtonBody"}
          textstyle={"tableButtonText"}
          // clickEvent={() => {
          //   handleChangeRolePopUps(true);
          // }}
        />
      ),
    },
    {
      name: "Action",
      cell: (row) => row.options,
    },
  ];

  const dataRow = data?.data?.map((data, index) => ({
    index: index + 1,
    personID: data?.id,
    username: data?.username?.slice(0, data?.username?.indexOf("@")),
    email: data.username,
    options: "dfsdfs",
  }));

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div className="bodyContainter">
      <NavBar link2={"navlist-active"} />
      <div className="container">
        <p className="title">User Account</p>{" "}
        <p className="title">Check out all users account</p>
        <div className="buttonContainer">
          <PrimaryButton
            title={"Add new user"}
            onClick={() => setOpenModal(true)}
            leftIcon={<HiPlus />}
          />
          <div className="searchBarContainer">
            <SearchBar searchtext={"Search"} />
          </div>
        </div>
        <>
          {isLoading ? (
            <div className="tableLoader">
              <Oval stroke="#5c2684" fill="white" width={30} height={30} />
            </div>
          ) : (
            <Table columns={columns} rows={dataRow} />
          )}
        </>
      </div>

      {openModal && (
        <PrimaryModal general>
          <div className="modalTop">
            <div className="topTextContainer">
              <p className="topTitle"> Add User </p>
              <p className="subTitle"> Fill in the form below </p>
            </div>
            <div className="iconWrapper">
              <CloseIcon onClick={handleClose} />
            </div>
          </div>
          <PrimaryInput
            name="username"
            type="text"
            placeholder="email@gmail.com"
            password={false}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            label="Username"
          />

          <p className="role"> Role </p>
          <div className="checkBoxContainer">
            <PurpleCheckbox
              checked={isAdminChecked}
              onChange={() => handleCheckboxChange("admin")}
              label="Administrator"
            />
            <PurpleCheckbox
              checked={isReadOnlyChecked}
              onChange={() => handleCheckboxChange("only")}
              label="Read Only"
            />
            <PurpleCheckbox
              checked={isLitigationChecked}
              onChange={() => handleCheckboxChange("litigation")}
              label="Litigation"
            />
            <PurpleCheckbox
              checked={isReadWriteChecked}
              onChange={() => handleCheckboxChange("write")}
              label="Read and Write"
            />
          </div>
          <div className="btnWrappper">
            <PrimaryButton
              title="Save"
              dark
              isLoading={addMutation.isLoading}
              onClick={handleSubmit}
            />
          </div>
        </PrimaryModal>
      )}
    </div>
  );
}

export default UserAccount;
