import React from "react";
import "./SearchBar.css";

function SearchBar({ searchstyle, searchtext }) {
  return (
    <div className="searchContainer" id={searchstyle}>
      <i className="fa-solid fa-magnifying-glass searchIcon"></i>
      <input
        type="text"
        className="searchInput"
        placeholder={searchtext}
        // value={<></>}
        // onChange={<></>}
      />
    </div>
  );
}

export default SearchBar;
