import React, { useEffect, useRef, useState } from "react";
import logoImage from "../../assets/imgs/Logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { useSelector } from "react-redux";
import { useJwt } from "react-jwt";
import { litigationData, perfectionData } from "./navData";

function NavBar() {
  const staffInfo = useSelector((state) => state.UserDataReducer?.staffInfo);
  const [openOptions, setOpenOptions] = useState(false);
  const [id, setId] = useState();
  const navRef = useRef();

  const navigate = useNavigate();
  const { decodedToken } = useJwt(staffInfo?.accessToken);

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setOpenOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavClick = (id, array) => {
    const findData = array?.find((item) => item.id === id);
    setId(id);
    if (findData?.option) {
      setOpenOptions(!openOptions);
    }
  };

  return (
    <div className="nav-container" ref={navRef}>
      <div className="navImageOptionCotainter">
        <img src={logoImage} alt="logo" className="nav-logo"></img>
        <hr className="line" />
        <div className="nav-option">
          {staffInfo?.user?.role[0] === "Litigation" && (
            <ul className="nav-ul">
              {litigationData?.map((data) => (
                <>
                  {data?.option ? (
                    <li>
                      <p
                        onClick={() => handleNavClick(data?.id, litigationData)}
                      >
                        {data?.title}
                      </p>

                      {id === data?.id && openOptions && (
                        <div className="nav-sub">
                          {data?.option?.map((data) => (
                            <ul className="nav-sub-ul">
                              <NavLink
                                className="sub-navlist"
                                to={data?.link}
                                onClick={() => setOpenOptions(false)}
                              >
                                {data?.title}
                              </NavLink>
                            </ul>
                          ))}
                        </div>
                      )}
                    </li>
                  ) : (
                    <li>
                      <NavLink className="navlist" to={data?.link}>
                        {data?.title}
                      </NavLink>
                    </li>
                  )}
                </>
              ))}
            </ul>
          )}

          {staffInfo?.user?.role[0] === "Administrator" && (
            <ul className="nav-ul">
              {perfectionData?.map((data) => (
                <li className="nav-item" key={data?.id}>
                  {data?.option ? (
                    <>
                      <p
                        onClick={() => handleNavClick(data?.id, perfectionData)}
                      >
                        {data?.title}
                      </p>

                      {id === data?.id && openOptions && (
                        <div className="nav-sub">
                          <ul className="nav-sub-ul">
                            {data?.option?.map((data) => (
                              <li className="sub-nav-item">
                                <NavLink
                                  className="sub-navlist"
                                  to={data?.link}
                                  onClick={() => setOpenOptions(false)}
                                >
                                  {data?.title}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </>
                  ) : (
                    <li className="nav-item">
                      <NavLink className="navlist" to={data?.link} i>
                        {data?.title}
                      </NavLink>
                    </li>
                  )}
                </li>
              ))}
            </ul>
          )}

          {staffInfo?.user?.role[0] === "Superuser" && (
            <ul className="nav-ul">
              {litigationData?.map((data) => (
                <>
                  {data?.option ? (
                    <li className="nav-item">
                      <p
                        onClick={() => handleNavClick(data?.id, litigationData)}
                      >
                        {data?.title}
                      </p>

                      {id === data?.id && openOptions && (
                        <div className="nav-sub">
                          {data?.option?.map((data) => (
                            <ul className="nav-sub-ul">
                              <NavLink
                                className="sub-navlist"
                                to={data?.link}
                                onClick={() => setOpenOptions(false)}
                              >
                                {data?.title}
                              </NavLink>
                            </ul>
                          ))}
                        </div>
                      )}
                    </li>
                  ) : (
                    <li className="nav-item">
                      <NavLink className="navlist" to={data?.link}>
                        {data?.title}
                      </NavLink>
                    </li>
                  )}
                </>
              ))}
            </ul>
          )}

          <div />
        </div>
      </div>
      <div className="nav-profile">
        <div className="nav-name-inducator">
          {staffInfo?.user?.displayName.toUpperCase().slice(0, 1)}
        </div>
        <p className="nav-username">{staffInfo?.user?.displayName}</p>
      </div>
    </div>
  );
}

export default NavBar;
