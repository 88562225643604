import React, { useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import "./style.css";

const DropDownComponent = ({
  value,
  options,
  placeholder,
  errorMessage,
  inputValue,
  label,
  setSelectedItem,
}) => {
  const [selected, setSelectedItems] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpenDropdown = () => {
    setOpen(!open);
  };

  const handleOptionClick = (value) => {
    setOpen(false);
    setSelectedItem(value);

    const display = options?.find((option) => option?.value === value);
    console.log(display);
    setSelectedItems(display?.label);
  };

  return (
    <div className="dropWrapper">
      <div className="dropTop">
        <p className="label">{label}</p>

        {errorMessage ? <div className="errMsg">{errorMessage}</div> : null}
      </div>
      <div className="dropDownWrapper">
        <div className="dropInner" onClick={handleOpenDropdown}>
          <div className="showList">
            {/* {selected ? ( */}
            <>
              <input
                type="text"
                className="drop-input"
                disabled
                value={
                  selected ? selected : inputValue ? inputValue : undefined
                }
                placeholder={placeholder}
              />
              {/* <p className="itemDark">{selected}</p> */}
            </>
            {/* ) : (
              <input type="text" value={inputValue} /> */}

            {/* // <p className="item">{placeholder}</p> */}
            {/* )} */}
          </div>
          <div className="showListIcon">
            {open ? (
              <HiChevronUp size={24} color="#4E5152" />
            ) : (
              <HiChevronDown size={24} color="#4E5152" />
            )}
          </div>
        </div>
        {open && (
          <>
            <div className="invisibleBackDrop" onClick={() => setOpen(false)} />
            <div className="dropDown">
              <ui className="listItems">
                {options?.map((option) => (
                  <li
                    className="listItem"
                    key={option.value}
                    onClick={() => handleOptionClick(option.value)}
                  >
                    {option.label}
                  </li>
                ))}
              </ui>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DropDownComponent;
