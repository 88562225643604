import React, { useEffect, useState } from "react";
import "./Litigation.css";
import NavBar from "../../components/NavBar/NavBar";

import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownComponent from "../../components/PrimaryInput/dropDown";
import TextArea from "../../components/TextArea";
import DateInput from "../../components/DatePicker/Date";
import { HiChevronLeft } from "react-icons/hi";
import { categoryList, currencyList, litigationSchema } from "../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  useGetSolicitorMutation,
  useGetLitigationQuery,
  usePostLitigationMutation,
  useUpdateLitigationMutation,
  useDeleteLitigationMutation,
  useUpdateDeletedLitigationMutation,
} from "../../services/litigation";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export const litigationInfoData = {
  suitName: "",
  caseNature: "",
  amountAgainst: "",
  amountFor: "",
  status: "",
  justiceInvolved: "",
  solicitorId: "",
  solicitorEmail: "",
};

function LitigationCreate() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(litigationSchema),
  });
  const token = useSelector(
    (state) => state.UserDataReducer?.staffInfo.accessToken
  );
  const [prefilledData, setPrefilledData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedSolicitor, setSelectedSolicitor] = useState();
  const [selectedLast, setSelectedLast] = useState();
  const [selectedNext, setSelectedNext] = useState();
  const [selectedUpdated, setSelectedUpdated] = useState();
  const [solicitorList, setSolicitorList] = useState([]);
  const [litigationInfo, setLitigationInfo] = useState(litigationInfoData);

  //endpoints
  const { data, refetch } = useGetLitigationQuery(token);
  const [solicitor] = useGetSolicitorMutation();
  const [postLitigation, postMutation] = usePostLitigationMutation();
  const [updateLitigation, updateMutation] = useUpdateLitigationMutation();
  const [deleteLitifation, deleteMutation] = useDeleteLitigationMutation();
  const [updateDeleteLitigation] = useUpdateDeletedLitigationMutation();

  const onHandleChange = (e) => {
    setLitigationInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleGetSolicitor = async () => {
    try {
      const response = await solicitor();

      setSolicitorList(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetSolicitor();
  }, []);

  const solicitorDataList = solicitorList?.map((data) => ({
    label: data?.contactPerson,
    value: data?.id,
    email: data?.solicitorEmail,
  }));

  console.log(solicitorDataList);

  useEffect(() => {
    if (selectedSolicitor) {
      const solicitorData = solicitorDataList.find(
        (item) => item?.value === selectedSolicitor
      );
      setLitigationInfo((prev) => ({
        ...prev,
        solicitorEmail: solicitorData?.email,
      }));
    }
  }, [selectedSolicitor]);

  useEffect(() => {
    if ((state?.id || state?.pendingId) && data?.data) {
      const prefilled = data.data.find(
        (item) =>
          item?.suitId === state?.id || item?.suitId === state?.pendingId
      );

      setPrefilledData(prefilled);

      setLitigationInfo((prev) => ({
        ...prev,
        caseNature: prefilled?.caseNature,
        suitName: prefilled?.suitName,
        status: prefilled?.status,
        justiceInvolved: prefilled?.justiceInvolved,
        amountFor: new Intl.NumberFormat().format(prefilled?.amountFor),
        amountAgainst: new Intl.NumberFormat().format(prefilled?.amountAgainst),
        solicitorId: prefilled?.solicitorId,
        solicitorEmail: prefilled?.solicitorEmail,
      }));
      setSelectedCurrency(prefilled?.currency);
      setSelectedCategory(prefilled?.category);
      setSelectedSolicitor(prefilled?.solicitorId);
      setSelectedNext(prefilled?.nextAdjournedDate.slice(0, 10));
      setSelectedLast(prefilled?.lastAdjournedDate.slice(0, 10));
      setSelectedUpdated(prefilled?.updatedDate.slice(0, 10));
    }
  }, [state?.id, state?.pendingId, data?.data]);

  const onSubmit = async (type) => {
    const payload = {
      suitName: litigationInfo?.suitName,
      caseNature: litigationInfo?.caseNature,
      category: selectedCategory,
      currency: selectedCurrency,
      amountAgainst: litigationInfo?.amountAgainst,
      amountFor: litigationInfo?.amountFor,
      status: litigationInfo?.status,
      justiceInvolved: litigationInfo?.justiceInvolved,
      solicitorId: selectedSolicitor,
      solicitorEmail: litigationInfo?.solicitorEmail,
      lastAdjournedDate: selectedLast,
      nextAdjournedDate: selectedNext,
      updatedDate: selectedUpdated,
    };

    if (type === "Create") {
      try {
        console.log("gggg", payload);
        const response = await postLitigation(payload);
        console.log("response", response);
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (type === "Edit") {
      try {
        console.log("edit", payload);
        const response = await updateLitigation({
          body: payload,
          trackerId: state?.id,
        });
        console.log("response edit", response);
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeleteLitigation = async () => {
    try {
      const response = await deleteLitifation(state?.id);

      console.log("response edit", response);
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);
        navigate("/litigation");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateDeleteLitigation = async (type) => {
    try {
      const payload = {
        trackerId: state?.pendingId,
        isApproved: type === "APPROVE" ? true : false,
      };
      const response = await updateDeleteLitigation(payload);

      console.log("response edit", response);
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);
        navigate("/litigation");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <NavBar link4={"navlist-active"} />
      <div className="container">
        <p className="title">Create Litigation</p>
        <div className="buttonContainer">
          <PrimaryButton
            title={"Back"}
            gray
            onClick={() => navigate(-1)}
            leftIcon={<HiChevronLeft />}
          />

          {state?.id ? (
            <div className="btnHold">
              <PrimaryButton
                title={"Edit"}
                light={false}
                onClick={() => onSubmit("Edit")}
                isLoading={updateMutation?.isLoading}
              />
              <PrimaryButton
                title={"Delete"}
                gray
                onClick={handleDeleteLitigation}
                isLoading={deleteMutation?.isLoading}
              />
            </div>
          ) : (
            <>
              {state?.pendingId ? (
                <div className="pendingBtnHold">
                  <PrimaryButton
                    title={"Approve"}
                    light={false}
                    onClick={() => handleUpdateDeleteLitigation("APPROVE")}
                    isLoading={updateMutation?.isLoading}
                  />
                  <PrimaryButton
                    title={"Decline"}
                    gray
                    onClick={() => handleUpdateDeleteLitigation("DECLINE")}
                    isLoading={deleteMutation?.isLoading}
                  />
                </div>
              ) : (
                <PrimaryButton
                  title="Submit"
                  light={false}
                  type="submit"
                  onClick={() => onSubmit("Create")}
                  isLoading={postMutation?.isLoading}
                />
              )}
            </>
          )}
        </div>
        <div className="formContainer">
          <p className="title">Litigation Information</p>
          <div className="form">
            <div className="gridWrapper">
              <DropDownComponent
                label={"Category"}
                placeholder={"Select Item"}
                options={categoryList}
                setSelectedItem={setSelectedCategory}
                inputValue={prefilledData?.category}
              />
              <DropDownComponent
                label={"Currency Type"}
                placeholder={"Select Item"}
                options={currencyList}
                setSelectedItem={setSelectedCurrency}
                inputValue={prefilledData?.currency}
              />
            </div>
            <div className="gridWrapper">
              <PrimaryInput
                label={"Suit Name"}
                placeholder={"Enter suit name"}
                password={false}
                type={"text"}
                // register={register}
                name="suitName"
                onChange={onHandleChange}
                value={litigationInfo?.suitName}
              />

              <PrimaryInput
                label={"Status of Case"}
                placeholder={"Enter status"}
                password={false}
                type={"text"}
                // register={register}
                name="status"
                onChange={onHandleChange}
                value={litigationInfo?.status}
              />
            </div>
            <TextArea
              label={"Nature of Case"}
              // register={register}
              type="text"
              name="caseNature"
              onChange={onHandleChange}
              value={litigationInfo?.caseNature}
            />
            <div className="gridWrapper">
              <PrimaryInput
                label={"Amount Against"}
                placeholder={"Enter amount"}
                password={false}
                type={"text"}
                // register={register}
                name="amountAgainst"
                onChange={onHandleChange}
                value={litigationInfo?.amountAgainst}
              />
              <PrimaryInput
                label={"Amount For"}
                password={false}
                type={"text"}
                placeholder={"Enter amount"}
                // register={register}
                name="amountFor"
                onChange={onHandleChange}
                value={litigationInfo?.amountFor}
              />
            </div>

            <div className="gridWrapper">
              <PrimaryInput
                label={"Justice Involved"}
                placeholder={"John Doe"}
                type={"text"}
                // register={register}
                name="justiceInvolved"
                onChange={onHandleChange}
                value={litigationInfo?.justiceInvolved}
              />
              <DateInput
                label={"Updated Date"}
                selectDate={setSelectedUpdated}
                value={selectedUpdated}
              />
            </div>
            <div className="gridWrapper">
              <DateInput
                label={"Next Adjourned Date"}
                selectDate={setSelectedNext}
                value={selectedNext}
              />
              <DateInput
                label={"Last Adjourned Date"}
                selectDate={setSelectedLast}
                value={selectedLast}
              />
            </div>
            <div className="gridWrapper">
              <DropDownComponent
                label={"Solicitor"}
                placeholder={"Select Solicitor"}
                options={solicitorDataList}
                setSelectedItem={setSelectedSolicitor}
                inputValue={prefilledData?.solicitorId}
              />
              <PrimaryInput
                label={"Solicitor Email Address"}
                password={false}
                type={"text"}
                placeholder={"Email address"}
                // register={register}
                name="solicitorEmail"
                value={litigationInfo?.solicitorEmail}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LitigationCreate;
