import toast from "react-hot-toast";
import * as yup from "yup";
import * as XLSX from "xlsx";
import CryptoJS from "crypto-js";

export const loginSchema = yup.object().shape({
	username: yup.string().required("Username is required"),
	password: yup.string().required("Password is required"),
	token: yup.string().required("Token is required"),
});

export const litigationSchema = yup.object().shape({
	suitName: yup.string().required("Username is required"),
	caseNature: yup.string().required("Password is required"),
	category: yup.string().required("Token is required"),
	currency: yup.string().required("Token is required"),
	amountAgainst: yup.string().required("Amount is required"),
	amountFor: yup.string().required("Amount is required"),
	status: yup.string().required("Token is required"),
	justiceInvolved: yup.string().required(" invitation is required"),
	solicitorName: yup.string().required(" invitation is required"),
	solicitorEmail: yup.string().required(" invitation is required"),
	lastAdjournedDate: yup.string().required(" invitation is required"),
	nextAdjournedDate: yup.string().required(" invitation is required"),
	updatedDate: yup.string().required(" invitation is required"),
});

export const categoryList = [
	{ value: "AGAINST_THE_BANK", label: "AGAINST_THE_BANK" },
	{ value: "FOR_THE_BANK", label: "FOR_THE_BANK" },
	{ value: "CONCLUDED", label: "CONCLUDED" },
];

export const currencyList = [
	{ value: "NGN", label: "NGN" },
	{ value: "USD", label: "USD" },
	{ value: "GBP", label: "GBP" },
	{ value: "EUR", label: "EUR" },
	{ value: "YEN", label: "YEN" },
];

export const handleLogout = async (logout, navigate) => {
	console.log("ssss");
	try {
		const response = await logout().unwrap();

		if (response?.statusCode === 200) {
			localStorage.clear();
			toast.success(response?.message);
			navigate("/");
		} else if (response?.statusCode === 401) {
			navigate("/");
			toast.error(response?.message);
		}
	} catch (err) {
		console.log(err);
		if (err.status === 401) {
			navigate("/");
		}
		toast.error(err?.message);
	}
};

export const handleDownloadSheet = (header, dataBody, documentName) => {
	// Merge header and dataBody arrays
	const mergedArray = [header, ...dataBody];

	// Create a new Excel workbook and sheet
	const workbook = XLSX.utils.book_new();
	const worksheet = XLSX.utils.aoa_to_sheet(mergedArray);

	// Append the sheet to the workbook
	XLSX.utils.book_append_sheet(workbook, worksheet, documentName);

	// Save the workbook as an Excel file (Report.xlsx)
	XLSX.writeFile(workbook, "Report.xlsx");
};

export const generateHeader = () => {
	let dateToUse = new Date();
	let UTCTimestamp = dateToUse.toISOString().replace("Z", "");
	let dateInToken = UTCTimestamp.replace("T", "")
		.replace(":", "")
		.replace(":", "")
		.substring(0, UTCTimestamp.length - 7);
	let shaOneEncrypt = CryptoJS.SHA512(
		dateInToken +
			process.env.REACT_APP_CLIENT_ID +
			process.env.REACT_APP_PUBLIC_X_TOKEN_PASSWORD
	);
	const apiHeader = {
		"x-token": shaOneEncrypt.toString(CryptoJS.enc.Hex),
		"Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_SUBSCRIPTION_KEY,
		"Ocp-Apim-Trace": true,
		UTCTimestamp: UTCTimestamp,
		client_id: process.env.REACT_APP_CLIENT_ID,
	};
	return apiHeader;
};

export const fetchPublicIP = async () => {
	try {
		const response = await fetch("https://api.ipify.org?format=json");
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const data = await response.json();
		return data.ip;
	} catch (error) {
		console.error(error);
		return null;
	}
};
