// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonBody {
  border-radius: 8px;
  border: 0.5px 0px 0px 0px;
  opacity: 0px;
  align-content: center;
  text-align: center;
  cursor: pointer;
}

.buttonText {
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 22.8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kCAAkC;EAClC,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".buttonBody {\n  border-radius: 8px;\n  border: 0.5px 0px 0px 0px;\n  opacity: 0px;\n  align-content: center;\n  text-align: center;\n  cursor: pointer;\n}\n\n.buttonText {\n  font-family: \"Poppins\", sans-serif;\n  font-size: 19px;\n  font-weight: 400;\n  line-height: 22.8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
