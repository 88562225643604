export const litigationData = [
  {
    id: 1,
    link: "/dashboard",
    title: "Dashbord",
  },
  {
    id: 2,
    link: "/litigation",
    title: "Litigation",
  },
];

export const perfectionData = [
  {
    id: 1,
    link: "/dashboard",
    title: "Dashbord",
  },
  {
    id: 2,
    link: "/userAccount",
    title: "User Accounts",
  },
  {
    id: 3,
    link: "/litigation",
    title: "Litigation",
    option: [
      {
        id: 1,
        link: "/litigation",
        title: "Litigation",
      },
      {
        id: 2,
        link: "/pending-litigation",
        title: "Pending Litigation",
      },
    ],
  },
  {
    id: 4,
    link: "/perfection",
    title: "Perfection",
    option: [
      {
        id: 1,
        link: "/perfection",
        title: "Perfection",
      },
      {
        id: 1,
        link: "/pending-perfection",
        title: "Pending Perfection",
      },
    ],
  },
];

export const superuserData = [
  {
    id: 1,
    link: "/dashboard",
    title: "Dashbord",
  },
  {
    id: 2,
    link: "/userAccount",
    title: "User Accounts",
  },
];
