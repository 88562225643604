import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import UserAccount from "../pages/UserAccount/UserAccount";
import IntroHeader from "../components/IntroHeader/IntroHeader";
import Perfection from "../pages/Perfection/Perfection";
import Litigation from "../pages/Litigation/Litigation";
import LitigationCreate from "../pages/Litigation/LitigationCreate";
import DeletedLitigation from "../pages/PendingDeletedLitigation";
import DeletedPendingPerfection from "../pages/PendingDeletedPerfection";
import PerfectionDetails from "../pages/Perfection/PerfectionDetails";
// import { useSelector } from "react-redux";
// import Protected from "./Protected";

const AppRouter = () => {
  // const staffInfo = useSelector((state) => state.StaffDataReducer?.staffInfo);

  return (
    <Routes>
      <Route index="/" element={<Login />} />
      {/* <Protected isVerified={staffInfo}> */}
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/userAccount" element={<UserAccount />} />
      <Route path="/introHeader" element={<IntroHeader />} />
      <Route path="/perfection" element={<Perfection />} />
      <Route path="/perfection-form" element={<PerfectionDetails />} />
      <Route
        path="/pending-perfection"
        element={<DeletedPendingPerfection />}
      />
      <Route path="/litigation" element={<Litigation />} />
      <Route path="/litigation-form" element={<LitigationCreate />} />
      <Route path="/pending-litigation" element={<DeletedLitigation />} />
      {/* </Protected> */}
    </Routes>
  );
};

export default AppRouter;
