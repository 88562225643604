import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { saveStaffInfo } from "./store/slice";
import { store } from "./store/store";
import { useIdleTimer } from "react-idle-timer";
import PrimaryModal from "./components/Modal";
import InactiveContent from "./components/InactiveContent";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "./utils";
import AppRouter from "./routes/AppRouter";
import { useLogoutMutation } from "./services/user";

function App() {
  const navigate = useNavigate();
  const staffInfoJSON = localStorage.getItem("staffInfo");
  const staffInfo = staffInfoJSON ? JSON.parse(staffInfoJSON) : {};
  const [showModal, setShowModal] = useState(false);
  const [counter, setCounter] = useState(60);

  const [logout] = useLogoutMutation();

  if (staffInfo) {
    store.dispatch(saveStaffInfo(staffInfo));
  }

  // //logout session
  const onIdle = () => {
    if (Object.keys(staffInfo).length !== 0) {
      setShowModal(true);
    }
  };

  const sessionTime = process.env.REACT_APP_SESSION_TIME;

  useIdleTimer({
    onIdle,
    timeout: parseFloat(sessionTime) * 60 * 1000,
    throttle: 500,
  });

  const handleCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setCounter(60);
    }

    if (counter === 0) {
      setShowModal(false);
      handleLogout(logout, navigate);
    }
  }, [showModal, counter, navigate, logout]);

  return (
    <div>
      {showModal && (
        <PrimaryModal general>
          <InactiveContent
            handleCancel={handleCancel}
            counter={counter}
            sessionTime={sessionTime}
          />
        </PrimaryModal>
      )}

      <AppRouter />

      <Toaster
        position="top-right"
        toastOptions={{
          className: "",
          style: {
            margin: "10px",
            padding: "10px",
            display: "inline-flex",
            fontSize: "14px",
            zIndex: 999999,
          },
          duration: 4000,
          error: {
            style: {
              background: "red",
              color: "white",
            },
            iconTheme: {
              primary: "white",
              secondary: "red",
            },
          },
          success: {
            style: {
              background: "green",
              color: "white",
            },
            iconTheme: {
              primary: "white",
              secondary: "green",
            },
          },
        }}
      />
    </div>
  );
}

export default App;
