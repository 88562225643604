// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intro-contaner {
  width: 311px;
  height: 65px;
}
.intro-header {
  font-size: 28px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
}
.intro-para {
  font-weight: 400;
  font-size: 19px;
  padding: 0px;
  margin: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/IntroHeader/IntroHeader.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;AACd;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,WAAW;AACb","sourcesContent":[".intro-contaner {\n  width: 311px;\n  height: 65px;\n}\n.intro-header {\n  font-size: 28px;\n  font-weight: 400;\n  padding: 0px;\n  margin: 0px;\n}\n.intro-para {\n  font-weight: 400;\n  font-size: 19px;\n  padding: 0px;\n  margin: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
