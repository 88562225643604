import React from "react";
import "./style.css";
import { ReactComponent as CloseIcon } from "../../assets/imgs/close.svg";

const PrimaryModal = ({ title, icon, handleModalClose, general, children }) => {
  return (
    <>
      {general ? (
        <>
          <div className="background">
            <div className="modalWrapper">
              <div className="modalContent">{children}</div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="background">
            <div className="modalWrapper">
              <div className="modalContent">
                <div className="top">
                  <div className="closeWrapper" onClick={handleModalClose}>
                    <CloseIcon />
                  </div>
                </div>

                <>{icon}</>
                <h4 className="modalTexts">{title}</h4>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default PrimaryModal;
