import React from "react";
import "./style.css";

const TextArea = ({
  label,
  register,
  name,
  padding,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <div className="check-wrap">
      <label className="label">{label}</label>
      <div className="input-container">
        {register ? (
          <textarea
            className="input"
            name={name}
            style={{ padding: `${padding}px` }}
            placeholder={placeholder}
            {...register(name)}
          />
        ) : (
          <textarea
            className="input"
            name={name}
            style={{ padding: `${padding}px` }}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        )}
      </div>
    </div>
  );
};

export default TextArea;
