import React from "react";
import "./style.css";
import { Oval } from "react-loading-icons";

const PrimaryButton = ({
  title,
  light,
  type,
  gray,
  onClick,
  leftIcon,
  isLoading,
}) => {
  return (
    <>
      {light ? (
        <div>
          <button className="btn" type={type} onClick={onClick}>
            {isLoading ? (
              <Oval stroke="#ffffff" fill="white" width={24} height={24} />
            ) : (
              <>
                {leftIcon && <div className="leftIcon">{leftIcon}</div>}
                {title}
              </>
            )}
          </button>
        </div>
      ) : (
        <>
          {gray ? (
            <div>
              <button className="btn gray" onClick={onClick}>
                {isLoading ? (
                  <Oval stroke="#ffffff" fill="white" width={24} height={24} />
                ) : (
                  <>
                    {leftIcon && <div>{leftIcon}</div>}
                    {title}
                  </>
                )}
              </button>
            </div>
          ) : (
            <div>
              <button className="btn dark" onClick={onClick}>
                {isLoading ? (
                  <Oval stroke="#ffffff" fill="white" width={24} height={24} />
                ) : (
                  <>
                    {leftIcon && <div>{leftIcon}</div>}
                    {title}
                  </>
                )}
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PrimaryButton;
