import React, { useState } from "react";
import logoImage from "../../assets/imgs/Logo.svg";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import PrimaryInput from "../../components/PrimaryInput";
import PrimaryButton from "../../components/PrimaryButton";
import { ReactComponent as CloseIcon } from "../../assets/imgs/close.svg";
import { loginSchema } from "../../utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useLoginUserMutation,
  useLogoutAllMutation,
  useGetTokenMutation,
  useResetUserMutation,
} from "../../services/auth";
import { savePassword, saveStaffInfo } from "../../store/slice";
import { store } from "../../store/store";
import toast from "react-hot-toast";
import PrimaryModal from "../../components/Modal";
import bgImage from "../../assets/imgs/logos.png";

function Login() {
  //navigation
  const navigate = useNavigate();

  //states
  const [openLogoutAll, setOpenLogoutAllModal] = useState(false);
  const [username, setUsername] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const [reset, setOpenResetModal] = useState(false);
  const [resetUsername, setResetUsername] = useState("");

  //endpoints
  const [login, loginMutation] = useLoginUserMutation();
  const [resetUserToken, resetMutation] = useResetUserMutation();
  const [logoutAll, logoutAllMutation] = useLogoutAllMutation();
  const [getToken, tokenMutation] = useGetTokenMutation();

  //react form
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  //login
  const submitForm = async (formValues) => {
    try {
      setUsername(formValues.username);
      store.dispatch(savePassword(formValues.password.trim()));
      console.log(formValues);
      const response = await login(formValues).unwrap();

      if (response?.statusCode === 200) {
        store.dispatch(saveStaffInfo(response?.data));
        localStorage.setItem("staffInfo", JSON.stringify(response?.data));
        toast.success(response?.message);
        navigate("/dashboard");
      }
    } catch (error) {
      if (error?.status === 409) {
        toast.error(error?.data?.Message);
        setOpenLogoutAllModal(true);
      }
      if (error?.status === 401) {
        toast.error(error?.data?.Message);
      }
      if (error?.status === 403) {
        toast.error(error?.data?.Message);
      }
    }
  };

  const handleLogoutAll = async () => {
    try {
      const response = await logoutAll({
        username: username,
      }).unwrap();

      if (response?.statusCode === 200) {
        toast.success(response?.message);
        setOpenLogoutAllModal(false);
      }
    } catch (error) {
      toast.error(error?.data?.Message);
    }
  };

  //generate token
  const generateToken = async () => {
    try {
      const response = await getToken({
        username: resetUsername,
      }).unwrap();

      if (response.statusCode === 200) {
        const message = response?.message;

        toast.success(message);
        setOpenResetModal(true);
      }
    } catch (error) {
      toast.error(error?.data?.Message);
    }
  };

  //reset password
  const resetUser = async () => {
    try {
      const response = await resetUserToken({
        username: resetUsername,
        token: resetToken,
      }).unwrap();

      if (response?.statusCode === 200) {
        toast.success(response?.message);
        setOpenModal(false);
        setOpenResetModal(false);
      } else if (response?.statusCode === 403) {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  //close modal
  const handleClose = () => {
    setOpenModal(false);
    setOpenResetModal(false);
    setOpenLogoutAllModal(false);
  };

  return (
    <div className="mainAuthContainer">
      <div className="leftAuthContainer">
        <img src={logoImage} alt="My Logo" className="logo" />

        <div className="box">
          <div className="formAuthContainer">
            <div className="welcomeBox">
              <h2>Welcome</h2>
              <p>Provide your details to access portal</p>
            </div>
            <form onSubmit={handleSubmit(submitForm)}>
              <PrimaryInput
                name="username"
                type="text"
                register={register}
                label="Username"
                error={errors?.username?.message}
                placeholder={"mail@johndoe.com"}
              />
              <PrimaryInput
                name="password"
                label="Password"
                placeholder="********"
                error={errors?.password?.message}
                password={true}
                register={register}
                type={"password"}
              />
              <PrimaryInput
                name="token"
                label="Token"
                type="password"
                password={true}
                register={register}
                error={errors?.token?.message}
                placeholder={"******"}
              />

              <PrimaryButton
                title="Sign In"
                light={false}
                type="submit"
                isLoading={loginMutation?.isLoading}
              />
            </form>
            <br />
            <p>
              Want to reset details?{" "}
              <span id="reset" onClick={() => setOpenModal(true)}>
                Reset
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="rightAuthContainer">
        <div className="bg">
          {/* <BackGroundIcon /> */}
          <img src={bgImage} className="image" alt="bgImage" />
        </div>
        <div className="welcomeText">
          <h2>My Bank and I</h2>
          <p>Perfection Tracker</p>
        </div>
      </div>

      {openModal && (
        <>
          <PrimaryModal general>
            {reset ? (
              <>
                <div className="modalTop">
                  <p className="topTitle"> Reset User </p>
                  <div className="iconWrapper">
                    <CloseIcon onClick={handleClose} />
                  </div>
                </div>

                <PrimaryInput
                  name="username"
                  type="text"
                  placeholder="abc.def"
                  value={resetUsername}
                  onChange={(e) => setResetUsername(e.target.value)}
                  label="Username"
                  password={false}
                />

                <PrimaryInput
                  name="token"
                  type="text"
                  placeholder="token"
                  password={false}
                  value={resetToken}
                  onChange={(e) => setResetToken(e.target.value)}
                  label="Token"
                />

                <div className="btnWrappper">
                  <PrimaryButton
                    title="Reset"
                    width="100%"
                    background={"#5C2684"}
                    color={"white"}
                    icon={""}
                    isLoading={resetMutation.isLoading}
                    loading={false}
                    leftIconName={<></>}
                    rightIconName={<></>}
                    border={""}
                    onClick={resetUser}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="modalTop">
                  <p className="topTitle">
                    {" "}
                    Please provide your email address{" "}
                  </p>
                  <div className="iconWrapper">
                    <CloseIcon onClick={handleClose} />
                  </div>
                </div>
                <PrimaryInput
                  name="username"
                  type="text"
                  placeholder="email@gmail.com"
                  password={false}
                  value={resetUsername}
                  onChange={(e) => setResetUsername(e.target.value)}
                  label="Username"
                />

                <div className="btnWrappper">
                  <PrimaryButton
                    title="Generate Token"
                    dark
                    isLoading={tokenMutation.isLoading}
                    onClick={generateToken}
                  />
                </div>
              </>
            )}
          </PrimaryModal>
        </>
      )}

      {openLogoutAll && (
        <PrimaryModal general>
          <>
            <div className="modalTop">
              <p className="topTitle"> Logout from all devices ? </p>
              <div className="iconWrapper">
                <CloseIcon onClick={handleClose} />
              </div>
            </div>

            <div className="allBtnWrappper">
              <PrimaryButton title="Cancel" gray onClick={handleClose} />
              <PrimaryButton
                title="Logout"
                dark
                isLoading={logoutAllMutation?.isLoading}
                onClick={handleLogoutAll}
              />
            </div>
          </>
        </PrimaryModal>
      )}
    </div>
  );
}

export default Login;
