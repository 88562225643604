// PurpleCheckbox.jsx
import React from "react";
import "./style.css";

const PurpleCheckbox = ({ checked, onChange, label }) => {
  return (
    <label className="purple-checkbox">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="checkmark"></span>
      {label && <span className="label-text">{label}</span>}
    </label>
  );
};

export default PurpleCheckbox;
