import React from "react";
import "./Button.css";

function Button({ text, icon, bodystyle, textstyle, clickEvent }) {
  return (
    <div className="buttonBody" id={bodystyle} onClick={clickEvent}>
      <small className="buttonText" id={textstyle}>
        <i className={icon}></i> {text}
      </small>
    </div>
  );
}

export default Button;
